

.home_background{
    background-size:cover;
    background-position: center center;
    position: relative;
    object-fit: cover;
    
}
.home_div{
    padding-top: 60px;
    
}
.font{
    position: relative;
    font-family: 'Times New Roman', Times, cursive;
    font-size:50px;
    font-weight: 520;
}
.font-div{
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top: 50px;
}
.top-icon-img{
    cursor: pointer;
}

@media screen and (max-width:600px) {
    #pop-bar{
        
        background: rgba(0, 0, 0, 5);
       
    }
    .home_div{
        padding-top: 50px;
        
    }
    .hr{
        display: none;
    }
    .home_background{
        background-size:cover;
        background-position: center center;
        position: relative;
        object-fit: cover;
        max-width: 100%;
        height: auto;
        
    }
    .font{
        
        position: relative;
        font-family: 'Times New Roman', Times, cursive;
        font-size:25px;
        font-weight: 520;
    }
    .font-div{
        padding-top: 40px;
        padding-bottom: 30px;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .top-icon-img{
        cursor: pointer;
    }
  }

@media screen and (min-width:601px) and (max-width:1200px) {
    .home_div{
        padding-top: 50px;
    }
    .hr{
        display: none;
    }
    .home_background{
        background-size:cover;
        background-position: center center;
        position: relative;
        object-fit: cover;
        max-width: 100%;
        height: auto;
        
    }
    .font{
        
        position: relative;
        font-family: 'Times New Roman', Times, cursive;
        font-size:25px;
        font-weight: 520;
    }
    .font-div{
        padding-top: 40px;
        padding-bottom: 50px;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .top-icon-img{
        
        cursor: pointer;
    }
}



