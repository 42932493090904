
.all-blog-font{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

}
.all-blogs-headers{
    
}
.date-font{
    font-size: 16px;
    font-weight: 200;
}
.all-blogs-divs{
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.all-blogs-div-mobile{
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    object-fit: contain;
    transform: scale(1);
    transition: ease-in;
    
    transition: 0.4s;
}
.all-blogs-div-mobile:hover{
    transform: scale(1.05);
    transition: ease-in;
    
    transition: 0.4s;
}
@media screen and (max-width:600px) {
    #pop-bar{
        
        background: rgba(0, 0, 0, 0);
        
    }
    .all-blogs-divs{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .all-blogs-div-mobile{
        width: 90%;
        padding-top: 40px;
    }
}
@media screen and (min-width:601px) and (max-width:1200px) {
    .all-blogs-divs{
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .all-blogs-div-mobile{
        width: 30%;
        padding-top: 0px;
    }
}