.search-line{
    display: none;
}
::placeholder {
    color: rgb(255, 255, 255);
    opacity: 0.6;
}
::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgb(255, 255, 255);
    opacity: 0.6;
}

.searchInputs {

    display: flex;
}

.search input {
    margin-top: 0px;
    background-color: #24783F;
    border: 0;
    border-radius: 4px;
    
    font-size: 18px;
    padding: 5px;
    height: 30px;
    width: 300px;
}

.searchIcon {
    height: 60px;
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;
}

input:focus {
    outline: none;
}

.searchIcon svg {
    font-size: 35px;
}

.dataResult {
    margin-top: 10px;
    border-radius: 4px;
    max-height: 80vh;
    background-color: #538b58;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    margin-left: 35px;
    width: 310px;
}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataResult .dataItem {
    width: 100%;
    
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
}

.dataItem p {
    margin-left: 10px;
}

a {
    text-decoration: none;
}

.dataItem:hover {
    background-color: rgb(27, 27, 27);
    opacity: 0.4;
}

#clearBtn {
    cursor: pointer;
}
@media screen and (max-width:600px) {
    .search-line{
        display:flex;
    }
    .search input {
        margin-top: 0px;
        background-color: #3E7840;
        border: 0;
        border-radius: 4px;
        
        font-size: 18px;
        padding: 5px;
        height: 30px;
        width: 100%;
    }
    .dataResult {
        margin-top: 0px;
        border-radius: 4px;
        max-height: 60vh;
        background-color: #538b58;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        width: 50vw;
        margin-left: 0px;
   
    }
}
@media screen and (min-width:601px) and (max-width:1200px) and (min-height:500px) {
    .search-line{
        display:flex;
    }
    .search input {
        margin-top: 0px;
        background-color: #3E7840;
        border: 0;
        border-radius: 4px;
        font-size: 30px;
        
        padding: 5px;
        height: 60px;
        width: 100%;
    }
    .dataResult {
        margin-top: 0px;
        border-radius: 4px;
        max-height: 60vh;
        background-color: #538b58;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        width: 50vw;
        margin-left: 0px;
   
    }
}
@media screen and (min-width:601px) and (max-width:1200px) and (max-height:500px) {
    .search-line{
        display:flex;
    }
    .search input {
        margin-top: 0px;
        background-color: #3E7840;
        border: 0;
        border-radius: 4px;
        font-size: 30px;
        
        padding: 5px;
        height: 30px;
        width: 100%;
    }
    .dataResult {
        margin-top: 0px;
        border-radius: 4px;
        max-height: 60vh;
        background-color: #538b58;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        width: 50vw;
        margin-left: 0px;
   
    }
}