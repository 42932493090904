
.search-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
#menu-bar{
    display: none;
}
#navbar-responsive{
    display: none;
}
.top-text {
    
    font-size:large;
    padding: 10px;
    transition: 0.3s;
    
    color: #FAF8ED;
    font-family:'Noto Sans';
}
.top-text:hover {
    
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
}


.top-row {
    display: flex;
    height: 60px;
    width: 100%;
    
    
    
}

.text-row {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content:flex-end;
    
    align-items: center;
    
}

.image-div {
    margin-left: 50px;
    margin-right: 100px;
    
}
.navbar-icon-image{
        
    height: 60px;
}

@media screen and (max-width:600px) {
    
    .search-div{
        display: none;
    }
    #menu-bar{
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        position: fixed;
        left: -300vh;
        transition: ease 700ms;
        width: 60%;
        height: 100vh;
        padding: 15px;
        background-color: #3E7840;
    }
    #close-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    
    #navbar-column{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 60vh;
    }
    #menu-bar-active{
        
        
        
        position: fixed;
        
        transform: translate3d(0px, 0, 0)
        
    }
    #navbar-responsive{
        display: flex;
        align-items: center;
        padding-left: 8px;
        z-index: 3;
    }
    
    
    .top-row {
        display: flex;
        height: 70px;
        width: 100vw;
        
        
        
    }
    .navbar-icon-image{
        height: auto;
        width: 160px;
    }
    .navbar-icon-image2{
        
        width: 100%;
    }
    .text-row{
        display: none;
        max-width: 100vw;
    }

    .image-div {
        padding-top: 5px;
        width: 100%;
        margin-left: 15px;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    
}

@media screen and (min-width:600px) and (max-width:1200px) and (min-height:500px) {
    .search-div{
        display: none;
    }
    #menu-bar{
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        position: fixed;
        left: -300vh;
        transition: ease 700ms;
        width: 60%;
        height: 100vh;
        padding: 15px;
        background-color: #3E7840;
    }
    #close-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    
    #navbar-column{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 60vh;
    }
    #menu-bar-active{
        
        
        
        position: fixed;
        
        transform: translate3d(0px, 0, 0)
        
    }
    #navbar-responsive{
        display: flex;
        align-items: center;
        padding-left: 8px;
        z-index: 3;
    }
    .top-text {
        
        font-size:35px;
        padding: 10px;
        transition: 0.3s;
        
        color: #FAF8ED;
        font-family:'Noto Sans';
    }
    .top-row {
        display: flex;
        height: 70px;
        width: 100vw;
        
        
        
    }
    
    .navbar-icon-image{
        height: auto;
        width: 245px;
    }
    .text-row{
        display: none;
        
    }

    .image-div {
        
        padding-top: 5px;
        width: 100%;
        margin-left: 15px;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}
@media screen and (min-width:600px) and (max-width:1200px) and (max-height:500px) and (orientation:landscape){
    .search-div{
        display: none;
    }
    #menu-bar{
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        position: fixed;
        left: -300vh;
        transition: ease 700ms;
        width: 60%;
        height: 100vh;
        padding: 15px;
        background-color: #3E7840;
    }
    #close-div{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    
    #navbar-column{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 60vh;
    }
    #menu-bar-active{
        
        
        
        position: fixed;
        
        transform: translate3d(0px, 0, 0)
        
    }
    #navbar-responsive{
        display: flex;
        align-items: center;
        padding-left: 8px;
        z-index: 3;
    }
    .top-text {
        
        font-size:17px;
        padding: 10px;
        transition: 0.3s;
        
        color: #FAF8ED;
        font-family:'Noto Sans';
    }
    .top-row {
        display: flex;
        height: 70px;
        width: 100vw;
        
        
        
    }
    
    .navbar-icon-image{
        height: auto;
        width: 245px;
    }
    .text-row{
        display: none;
        
    }

    .image-div {
        
        padding-top: 5px;
        width: 100%;
        margin-left: 15px;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        display: flex;
    }
}
