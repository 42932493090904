.socials{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    
}
.bottom-bar-div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding-left: 90px;
}

.bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-shadow: 0px 10px 20px 10px #818181;
    position: relative;
    background-color: #268043;

}

.bottom-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.bottom-text:hover {

    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

.bottom-image-div {
    display: inline-block;
    position: relative;
    padding-left: 200px;
    padding-right: 20px;


}

.bottom-image-div2 {
    display: inline-block;
    position: relative;

    padding-right: 20px;


}

.bottom-image {
    height: auto;

    padding-top: 5px;
}

.bottom-bar-text {
    font-size: small;
    color: aliceblue;
}

.bottom-text-column {

    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width:600px) {
    .bottom-bar-div {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding-left: 0px;
        width: 95vw;
        
    }
    .socials{
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        width: 95vw;
    }

    .bottom-bar {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;


        position: relative;
        background-color: #268043;

    }

    .bottom-image {
        height: auto;
        width: 100%;
    }
}

@media screen and (min-width:601px) and (max-width:1200px) {
    .bottom-bar-div {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding-left: 0px;
    }
    .bottom-bar {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        

        position: relative;
        background-color: #268043;

    }

    .bottom-image {
        height: auto;
        width: 100%;
    }
}