.arrow {
    
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    padding-bottom: 100px;
    position: relative;
    align-items: center;
    
}

.arrow2 {
    display: flex;
    align-items: center;
    
    width: 30%;
    height: 100%;
    position: relative;
    z-index: 3;
    background-color: transparent;
    opacity: 0.3;
}

.img:hover {
    cursor: pointer;
}
.arrow3 {
    display: flex;
    align-items: center;
    
    width: 30%;
    height: 100%;
    position: relative;
    z-index: 3;
    background-color: transparent;
    opacity: 0.3;
}
.outerLeft {
    position:relative;
    width: 50%;
    height: 50vh;
    z-index: 3;
    display: flex;
    justify-content: flex-end;

    
}
.outerRight {
    position:relative;
    width: 50%;
    height: 50vh;
    z-index: 3;


    
}

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 70%;
    
}
  
.slideshowSlider {
    white-space: nowrap;
    
    transition: ease 1000ms;
}
  
.slide {
    display: inline-block;
    
    
    width: 100%;
    border-radius: 20px;

    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    
    
}
  
  /* Buttons */
  

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  
    cursor: pointer;
    margin: 15px 7px 0px;
  
    background-color: #c4c4c4;
}
  
.slideshowDot.active {
    background-color: #000000;
}

@media screen and (max-width:600px) {
    .slide {
        display: inline-block;
        
        
        width: 100%;
        border-radius: 20px;
    
        
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        
        height: 50vh;
    }
    .slideshow {
        margin: 0 auto;
        overflow: hidden;
        max-width: 95%;
        
    }
    .outerLeft {
        display: none;
        
    }
    .outerRight {
        display: none;
    
    
        
    }
    .img{
        width: 200px;
        height: 50px;
        
    }
    .arrow3 {
        display: flex;
        align-items: center;
        
        width: 15%;
        height: 100%;
        position: relative;
        z-index: 3;
        background-color: transparent;
        opacity: 0.3;
    }
    .arrow2 {
        display: flex;
        align-items: center;
        padding-right: 37px;
        width: 15%;
        height: 100%;
        position: relative;
        z-index: 3;
        background-color: transparent;
        opacity: 0.3;
    }
    .arrow {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        
        display: flex;
        padding-bottom: 50px;
        position: relative;
        align-items: center;
        
    }
    .slide{
        display: inline-block;
    
    
        max-width: 100%;
        border-radius: 20px;

        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    
    }
    .img2{
        max-width: 100%;
        
    }
}
@media screen and (min-width:601px) and (max-width:1200px) {  
    .outerLeft {
        position:relative;
        width: 20%;
        height: 150px;
        z-index: 3;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 30px;
        
    }
    .outerRight {
        position:relative;
        width: 20%;
        height: 150px;
        z-index: 3;
        padding-bottom: 30px;
    
    
        
    }
    .img{
        width: 200px;
        height: 50px;
        
    }
    .arrow3 {
        display: flex;
        align-items: center;
        
        width: 15%;
        height: 100%;
        position: relative;
        z-index: 3;
        background-color: transparent;
        opacity: 0.3;
    }
    .arrow2 {
        display: flex;
        align-items: center;
        padding-right: 37px;
        width: 15%;
        height: 100%;
        position: relative;
        z-index: 3;
        background-color: transparent;
        opacity: 0.3;
    }
    .arrow {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        
        display: flex;
        padding-bottom: 50px;
        position: relative;
        align-items: center;
        
    }
    .slide{
        display: inline-block;
    
    
        max-width: 100%;
        border-radius: 20px;

        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    
    }
    .img2{
        max-width: 100%;
        
    }
}

