.bottom-blogs-line{
    display: none;
}
.bottom-h1-divs{
    width: 40vw;
    display: flex;
    justify-content: center;
}
.bottom-blogs-div{
    transform: scale(1);
    transition: ease-in;
    
    transition: 0.4s;
}
.bottom-blogs-div:hover{
    transform: scale(1.05);
    transition: ease-in;
    
    transition: 0.4s;
}
.main-div {
    width: 100%;

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;

    object-fit: cover;
    padding-bottom: 30px;
}

.main-div2 {
    width: 100%;

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;

    object-fit: cover;
    padding-bottom: 30px;

}

.main-div3 {
    width: 100%;

    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;

    object-fit: cover;


}

.main {
    width: 100%;
    flex-direction: column;
    display: inline-flex;
    position: relative;

}

.row-div {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 40vw;
}

.row-div2 {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 40vw;
}

.bottom-photo-div {
    object-fit: contain;
    width: 40vw;
    height: 300px;
    

}


.img:hover {
    cursor: pointer;
    
}

@media screen and (max-width:440px) {
    .bottom-blogs-line{
        display: flex;
    }
    .bottom-h1-divs{
        width: 60vw;
        display: flex;
        justify-content: center;
    }
    .bottom-blogs-div{
        transform: scale(1);
        transition: ease-in;
        transition: 0.4s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .main-div {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        object-fit: cover;
        padding-bottom: 0px;
    }

    .main-div2 {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        object-fit: cover;
        padding-bottom: 0px;
    }

    .main-div3 {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        object-fit: cover;
        padding-bottom: 00px;
    }

    .row-div {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
        padding-bottom: 30px;
        height: 30vh;
        min-height: 300px;
    }

    

    .row-div2 {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
        padding-bottom: 30px;
        height: 30vh;
        min-height: 300px;
    }

    .bottom-photo-div {
        object-fit: contain;
        width: 100%;
        height: 150px;
    }
}
@media screen and (min-width:441px) and (max-width:1200px) {
    .bottom-blogs-line{
        display: none;
    }
    .bottom-h1-divs{
        width: 45vw;
        display: flex;
        justify-content: center;
    }
    .bottom-blogs-div{
        transform: scale(1);
        transition: ease-in;
        transition: 0.4s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .main-div {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        object-fit: cover;
        padding-bottom: 0px;
    }

    .main-div2 {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        object-fit: cover;
        padding-bottom: 0px;
    }

    .main-div3 {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center center;
        flex-direction: column;
        object-fit: cover;
        padding-bottom: 0px;
    }

    .row-div {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
        padding-bottom: 0px;
        height: 30vh;
        min-height: 300px;
    }

    

    .row-div2 {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
        height: 30vh;
        min-height: 300px;
    }

    .bottom-photo-div {
        object-fit: contain;
        width: 100%;
        height: 150px;
    }
}

@media screen and (min-width:601px) and (max-width:1200px) {
    .bottom-h1-divs{
        width: 30vw;
        display: flex;
        justify-content: center;
    }
    .main-div {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: flex-start;
        background-size: cover;
        background-position: center center;
        flex-direction: row;
        object-fit: cover;
        padding-bottom: 30px;
    }

    .main-div2 {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: flex-start;
        background-size: cover;
        background-position: center center;
        flex-direction: row;
        object-fit: cover;
        padding-bottom: 30px;
    }

    .main-div3 {
        width: 100%;
        height: auto;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: flex-start;
        background-size: cover;
        background-position: center center;
        flex-direction: row;
        object-fit: cover;
        padding-bottom: 30px;
    }

    .row-div {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;
    }

    .row-div2 {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;
    }

    .bottom-photo-div {

        object-fit: contain;
        width: 100%;
        height: 200px;
    }
}