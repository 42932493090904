

.pagination-dots.active {
    background-color: #538B58;
}
.pagination-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.number-font {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
    text-decoration: none;
    color: #261210;
}

.next {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    height: fit-content;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
    font-weight: 300;
    font-style: normal;
    color: #261210;
    background-color: #538B58;
}

.prev {
    background-color: #538B58;
    color: #261210;
    height: fit-content;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}

.prevdisabled {
    background-color: #BDBFB8;
    pointer-events: none;
    height: fit-content;



}

.prevdisabled-a {
    height: fit-content;
    opacity: 0.7;
    color: #261210;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;

}

.nextdisabled {
    background-color: #BDBFB8;
    pointer-events: none;



}

.nextdisabled-a {
    opacity: 0.7;
    color: #261210;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
}

@media screen and (max-width:600px) {
    .pagination-row{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .next {
        display: flex;
        height: auto;
        align-items: center;
    }

    .prev {
        display: flex;
        height: auto;
        align-items: center;
    }

    .nextdisabled {
        display: flex;
        height: auto;
        align-items: center;



    }
    .prevdisabled {
        display: flex;
        height: auto;
        align-items: center;
    
    
    
    }
}