.list-padding{
    padding-bottom: 10px;
}
.bottom-padding{
    padding-bottom: 130px;
}
.might-like-top-div{
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.might-like-div{
    width: 40%;
    transform: scale(1);
    transition: ease-in;
    
    transition: 0.4s;
}
.might-like-div:hover{
    transform: scale(1.05);
    transition: ease-in;

    transition: 0.4s;
}
.amazon-button {
    display: flex;
    align-items: center;
    background-color: #d34600;
    border-radius: 10px;
    cursor: pointer;

    transform: scale(1);
    transition: ease-in;

    transition: 0.4s;
}

.amazon-button:hover {
    transform: scale(1.1);
    transition: ease-in;

    transition: 0.4s;
}

.template-p-times {
    font-family: 'Times New Roman', Times, serif;
    font-size: 15px;
    font-weight: 500;
    opacity: 0.7;

}

.blog-main-div {
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 70px;
    background-color: #E6EDF2;

}

.pros-text {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bolder;
    font-size: 22px;
    color: green
}

.cons-text {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: bolder;
    font-size: 22px;
    color: red
}

.blog-column-div1 {
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

}

.sticky-div {
    position: fixed;
}

.pros-cons-div {
    display: flex;
    position: relative;
    justify-content: space-evenly;
    padding-bottom: 20px;
}

.blog-left-div {
    width: 100%;
    position: relative;

}

.blog-right-div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    flex-direction: column;

}

.blog-top-div {
    width: 100%;
    height: 30%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    background-color: white;

}

.specs {
    width: 100%;
    padding-left: 0%;
}

.template-header {
    font-family: "Merriweather", serif;
    font-size: 45px;
    font-weight: 700;
    font-style: normal;

}

.product-header {
    font-family: "Merriweather", serif;
    font-size: 35px;
    font-weight: 700;
    font-style: normal;
}


.spec-text {

    font-size: 19px;
}

.paragraphs {
    font-family: "Merriweather", serif;
    font-size: 17px;
    font-weight: 250;
    font-style: italic;
}
.paragraphs-bottom{
    font-family: "Merriweather", serif;
    font-size: 17px;
    font-weight: 600;
    font-style: italic;
    width: 100%;
    color: black;
    display: flex;
    justify-content: center;
}

.zero-padding {
    padding-left: 100px;
    padding-right: 100px;
}

@media screen and (max-width:600px) {
    .bottom-padding{
        padding-bottom: 30px;
    }
    .might-like-top-div{
        padding-top: 0px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .might-like-div{
        width: 100%;
        transform: scale(1);
        transition: ease-in;
        padding-bottom: 30px;
        transition: 0.4s;
    }
    .paragraphs-bottom{
        font-family: "Merriweather", serif;
        font-size: 17px;
        font-weight: 600;
        font-style: italic;
        width: 100%;
        color: black;
        display: flex;
        justify-content: center;
    }
    #pop-bar {

        background: rgba(0, 0, 0, 0);

    }

    .template-overflow {
        overflow-x: hidden;
    }

    .blog-right-div {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 30px;

    }

    .sticky-div {
        position: relative;
    }

    .pros-cons-div {
        display: flex;
        position: relative;
        justify-content: space-evenly;

        padding: 10px;
    }

    .product-header {
        font-size: 30px;
    }

    .template-header {
        font-size: 35px;

    }

    .specs {

        padding-left: 0px;
    }

    .spec-text {

        font-size: 16px;
    }

    .blog-top-div {
        width: 100%;
        height: 30%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 20px;
    }

    .blog-left-div {
        width: 100%;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
    }

    .blog-main-div {
        padding: 30px;
        background-color: white;
    }

    .zero-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (min-width:601px) and (max-width:1200px) {
    .bottom-padding{
        padding-bottom: 30px;
    }

    .zero-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .blog-right-div {
        width: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        padding-top: 30px;

    }

    .sticky-div {
        position: relative;
    }

    .pros-cons-div {
        display: flex;
        position: relative;
        justify-content: space-evenly;

        padding: 30px;
    }

    .product-header {
        font-size: 30px;
    }

    .template-header {
        font-size: 35px;
    }

    .specs {
        width: 100%;
        padding-left: 0px;
    }

    .spec-text {

        font-size: 16px;
    }

    .blog-top-div {
        width: 100%;
        height: 30%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 20px;
    }

    .blog-left-div {
        width: 100%;
        position: relative;
        padding-left: 0px;
        padding-right: 0px;
        padding: 15px;
    }

    .blog-main-div {
        padding: 30px;
        background-color: white;

    }
}