

.about-us-header{
    text-align: center;
    font-size: 50px;
    color: #4b5b2f;
    font-family: "Merriweather", serif;
    font-weight: 400;
    font-style: normal;
}
.about-us-paragraphs{
    color: #000000;
    font-weight: 350;
    font-size: 24px;
    padding-left: 50px;
    padding-right: 50px;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    
    font-style: normal;
    font-variation-settings:
      "wdth" 90;
}
.about-us-main{
    overflow: hidden;
}
.about-us-img{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    
}
.about-us-img2{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 100%;
    
}
.about-us-second{
    padding-top: 100px;
    width: 100%;
    height: 50%;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
}
.about-us-third {
    padding-top: 60px;
    width: 100%;
    height: 50%;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
}
.about-us-img-div{
    width: 50%;
    height: 50%;
}
.about-us-paragraph-div{
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width:600px) {
    #pop-bar{
        
        background: rgba(0, 0, 0, 0);
        
    }
    .about-us-img-div{
        width: 80%;
        height: 50%;
    }
    .about-us-third {
        padding-top: 40px;
        width: 100%;
        height: 50%;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
    }
    .about-us-second{
        flex-direction: column;
        align-items: center;
    }
    .about-us-paragraphs{
        color: #000000;
        font-weight: 350;
        font-size: 18px;
        padding-left: 0px;
        padding-right: 0px;
        font-family: "Open Sans", sans-serif;
        font-optical-sizing: auto;
        
        font-style: normal;
        font-variation-settings:
          "wdth" 90;
    }
    .about-us-img{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        width: 100%;
        
    }
    .about-us-img2{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        width: 100%;
        
    }
    .about-us-paragraph-div{
        width: 80%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (min-width:601px) and (max-width:1200px) {
    .about-us-img-div{
        width: 80%;
        height: 50%;
    }
    .about-us-paragraph-div{
        width: 80%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .about-us-third {
        padding-top: 40px;
        width: 100%;
        height: 50%;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        align-items: center;
    }
    .about-us-second{
        flex-direction: column;
        align-items: center;
    }
    .about-us-img{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        width: 100%;
        
    }
    .about-us-img2{
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        width: 100%;
        
    }
}