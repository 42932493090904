.terms-of-use-main{
    margin-left: 200px;
    margin-right: 200px;
    padding : 80px;
    background-color: white;
}
.terms-of-use-h-font{
    display: flex;
    justify-content: center;
    font-size: 35px;
}
.terms-of-use-p-font{
    
    font-size: 20px;
}

@media screen and (max-width:600px) {
    #pop-bar{
        
        background: rgba(0, 0, 0, 0);
        
    }
    .terms-of-use-main{
        margin-left: 0px;
        margin-right: 0px;
        padding : 20px;
        background-color: white;
    }
}
@media screen and (min-width:601px) and (max-width:1200px) {
    .terms-of-use-main{
        margin-left: 0px;
        margin-right: 0px;
        padding : 50px;
        background-color: white;
    }
}